@import "~bootstrap/scss/bootstrap";
@import "color";
@import "font";

/* Breadcrumb */
.breadcrumb {
     height: 5em;
     background-color: $white;
     font-weight: 400;
     justify-content: space-between;
     padding: 1em 2em;
     width: calc(100% - 320px);
     position: fixed;
     z-index: 80;
     border-radius: 0;

     .-b__item {
          display: flex;
          height: 100%;
          font-family: "sukhumvit-semiBold";
     }

     .-b__button,
     .-b__button-md {
          display: flex;
          width: 50%;
          justify-content: flex-end;

          > .btn {
               width: 50%;
          }

          > .btn:nth-child(n + 1) {
               margin: 0 0 0 0.5em;
          }
     }

     .-b__button-md {
          display: none;
     }

     &-item {
          align-items: center;

          & + .breadcrumb-item::before {
               content: ">";
               color: $black;
          }

          a {
               color: $secondary-text;
          }

          &.active {
               color: $black;
          }
     }
}

.step {
     .breadcrumb {
          position: relative;
          height: auto;
          justify-content: left;
          padding: 0;
          margin: 0;
          width: 100%;
          z-index: 79;

          &-item {
               color: white;
               border-radius: 5px;
               margin: 0.25em;
               padding: 0;

               & + .breadcrumb-item::before {
                    content: ">";
                    color: $black;
               }

               &.active,
               a {
                    b {
                         padding: 0.5em;
                         background: $success;
                         border: 1px solid $success;
                         border-radius: 5px;
                    }
               }

               a {
                    padding: 0.35em;
                    border-radius: 5px;
                    border: 1px solid $bc-border;
                    background: $bc-primary;

                    b {
                         border: 1px solid $bc-primary;
                         background: $bc-primary;
                    }
               }

               b {
                    font-size: 0.85em;
               }
          }
     }
}

.label {
     .breadcrumb {
          width: calc(100% - 320px);
          // width: 100%;
          background-color: #fff9d5;
          position: fixed;
          right: 0;
          bottom: 0;
          height: 5em;
          padding: 0;
          margin: 0;
          padding: 1em 4%;
          align-items: center;

          .__right {
               text-align: right;
          }

          .__row-right {
               width: 42%;

               .row {
                    align-items: center;
               }

               .col {
                    margin: 0 15px;
                    color: #1d419b;
               }
          }

          .color {
               &.__success {
                    color: #88b759;
               }
          }

          .btn-color {
               &.__default {
                    background-color: #fff;

                    &:hover {
                         color: #1d419b;
                         background-color: rgb(223, 220, 220);
                    }

                    .fa-print {
                         &:before {
                              color: #1d419b;
                         }
                    }

                    &.btn-outline-primary {
                         border-color: #1d419b;
                         color: #1d419b;
                    }
               }
          }

          .__image {
               height: auto;
               width: 7%;
          }
     }
}

.order {
     .breadcrumb {
          width: calc(100% - 320px);
          background-color: #fff9d5;
          position: fixed;
          right: 0;
          top: 5em;
          height: 2.75em;
          padding: 0;
          margin: 0;
          padding: 0 0 0 2em;
          align-items: center;

          .color {
               &.__primary {
                    color: #1d419b;
               }
          }
     }
}

/* Button */
.btn {
     width: 100%;
     font-weight: 200;
     border-radius: 0.5rem;
     padding: 0.75rem;
     // margin-bottom: 0.25em;

     .fa,
     .fas {
          &-edit,
          &-print {
               color: $white;
          }
     }

     > .-icon {
          margin: 0 0.5em;
     }

     &.-small {
          padding: 0.375rem 0.75rem;
     }

     &-primary {
          background-color: $primary-color;

          &:disabled {
               background: $disabled-color;
               border: $primary-text;
               color: $black;
          }
     }

     &-link {
          width: fit-content;
          color: $primary-color;
     }

     &-danger {
          background-color: $danger;
     }

     &-success {
          background-color: $success;

          &:disabled {
               background: $disabled-color;
               border: $primary-text;
               color: $black;
          }
     }

     &-radius {
          border-radius: 25px;
          width: auto;
          height: fit-content;
          padding: 0.5rem 0.75rem;
          color: white;
     }

     &-light {
          background: white;
          border: 1px solid $primary-color;
          color: $primary-color;
     }
}

/* Table */
.react-bootstrap-table {
     background: $white;

     // word-break: break-all;

     .fa,
     .fas {
          &-edit,
          &-print {
               color: $font-table;

               &.active {
                    color: $primary-color;
                    cursor: pointer;
               }
          }
     }

     table {
          // min-width: 60em;
          &#add-table {
               &.table {
                    width: 100%;
                    max-height: 20em;
                    min-width: 30em;

                    tbody {
                         tr {
                              td {
                                   padding: 0.5rem;
                                   word-break: break-all;
                              }
                         }
                    }
               }
          }

          thead {
               .header-custom {
                    &.__left {
                         border-top-left-radius: 0.25rem;
                    }

                    &.__checkAcc {
                         border-top-left-radius: 0.25rem;
                         text-align: center;
                    }

                    &.__right {
                         border-top-right-radius: 0.25rem;
                    }

                    &.__btn {
                         max-width: 15em;
                    }

                    &.__5em {
                         width: 5em;
                    }
                    &.__6em {
                         width: 6em;
                    }

                    &.__btn-ss {
                         width: 7em;
                    }

                    &.__btn-s {
                         width: 8em;
                    }
                    &.__9em {
                         width: 9em;
                    }
                    &.__10em {
                         width: 10em;
                    }

                    &.__11em {
                         width: 11em;
                    }

                    &.__btn-m {
                         width: 12em;
                    }

                    &.__btn-ml {
                         width: 15em;
                    }

                    &.__btn-l {
                         width: 30em;
                    }

                    // in order leasing
                    &.__btn-col1 {
                         width: 12em;
                         background-color: #fff;
                    }

                    &.__btn-col2 {
                         width: 33em;
                         background-color: #fff;
                    }

                    &.__btn-col3 {
                         width: 22em;
                         background-color: #fff;
                    }

                    &.__btn-col4 {
                         background-color: #fff;
                         width: 10em;
                    }

                    &.__btn-match-car {
                         width: 13em;
                    }

                    &.__icon {
                         width: 5em;
                    }

                    &.__no {
                         width: 5em;
                         text-align: center;
                    }

                    //percentage
                    &.__5p {
                         width: 5%;
                    }

                    &.__9p {
                         width: 9%;
                    }

                    &.__10p {
                         width: 10%;
                    }

                    &.__12p {
                         width: 12%;
                    }
               }

               th {
                    border: 0;
                    vertical-align: middle;
               }

               tr {
                    background: $second-color;
                    color: $white;

                    &.list-header {
                         display: none;
                    }
               }
          }

          tbody {
               .body-custom {
                    &.__center {
                         text-align: center;
                    }
               }

               &.list-tbody {
                    tr {
                         td {
                              padding: 0 0.25em;
                              vertical-align: middle;

                              .form-group {
                                   margin: 0;
                              }
                         }

                         // td:nth-last-child(1),
                         // td:nth-last-child(2) {
                         //   width: 6em;
                         // }
                    }
               }

               &.list-left {
                    tr {
                         td {
                              padding: 0 0.25em;
                              vertical-align: middle;

                              .form-group {
                                   margin: 0;
                              }
                         }

                         td:nth-child(1) {
                              width: 13%;
                         }

                         td:nth-child(2) {
                              width: 45%;
                         }

                         td:nth-child(3) {
                              width: 30%;
                         }
                    }
               }

               tr {
                    color: $black;

                    &.row-custom {
                         background: $bg-table;

                         &.__summary {
                              background: $bg-table;
                              border-top: 1px solid #dee2e6;
                         }

                         &.__summary-total {
                              background: $bg-footer;

                              td {
                                   > .text {
                                        font-size: 1.25em;
                                        color: blue;
                                   }
                              }
                         }

                         &.__summary-gold {
                              background: #f5e94b;

                              td {
                                   > .text {
                                        font-size: 1.25em;
                                   }
                              }
                         }

                         &.__summary-danger {
                              background: #ffe1e1;

                              td {
                                   > .text {
                                        font-size: 1.25em;
                                   }
                              }
                         }

                         &.__summary-detail {
                              background: #effaff;
                              // border: 1px solid rgb(190, 198, 240);
                         }
                    }
               }

               td {
                    padding: 0.75em;
                    vertical-align: middle;
                    font-family: "sukhumvit-light";
                    color: $secondary-text;
                    // word-wrap: break-word;

                    .form-group {
                         margin: 0;
                    }

                    a:not([href]):not([class]) {
                         text-decoration: underline;
                         color: $second-color;
                    }
               }
          }

          tfoot {
               th {
                    &.summary-custom {
                         background: $bg-footer;
                         border: 0;

                         &.__yellow {
                              background: $bg-body-2;
                              white-space: break-spaces;
                              &.total {
                                   font-size: 1.25em;
                                   color: red;
                              }

                              &.__number-green {
                                   font-size: 1.25em;
                                   color: green;
                              }
                         }

                         &.__number-blue,
                         &.__number-danger {
                              color: $second-color;
                              font-size: 1.25em;
                              font-family: "sukhumvit-semiBold";
                         }

                         &.__number-danger {
                              color: $danger;
                         }
                    }

                    &.summary-custom-4px {
                         background: $bg-footer;
                         border: 0;
                         padding: 4px;

                         &.__yellow {
                              background: $bg-body-2;
                              white-space: break-spaces;
                         }

                         &.__number-blue,
                         &.__number-danger {
                              color: $second-color;
                              font-size: 1.25em;
                              font-family: "sukhumvit-semiBold";
                         }

                         &.__number-danger {
                              color: $danger;
                         }
                    }
               }
          }

          th,
          tr {
               font-weight: 200;
          }

          td {
               border: 0;
          }
     }
}

.index {
     height: fit-content;

     &.__primary {
          .react-bootstrap-table {
               min-width: 53em;
          }
     }

     &.__modal {
          .react-bootstrap-table {
               min-width: 47em;
          }
     }

     &.__custom {
          min-width: 50em;

          .react-bootstrap-table {
               max-height: 50vh;
          }
     }

     &.__full {
          .react-bootstrap-table {
               min-width: 850px;
               width: 100%;
          }
     }

     &.__document {
          padding: 0px 15px;

          .react-bootstrap-table {
               table {
                    thead {
                         tr {
                              background: white;
                              color: $black;
                              font-family: "sukhumvit-semiBold";
                              border-bottom: 2.5px solid $second-color;
                         }
                    }
               }
          }
     }

     &.__pay {
          min-width: 50em;

          .react-bootstrap-table {
               height: 100% !important;
               max-height: unset !important;
          }
     }

     &.__summary {
          min-width: 55em;
          // padding: 0px 15px;

          .react-bootstrap-table {
               .table {
                    tbody {
                         td {
                              padding: 0.25em 12px;
                         }
                    }
               }
          }
     }

     &.__summary-no-padding {
          min-width: 66em;
          // padding: 0px 15px;

          .react-bootstrap-table {
               .table {
                    tbody {
                         td {
                              padding: 1em 12px;
                         }
                    }
               }
          }
     }

     &.__summary-custom-padding {
          min-width: 82em;
          // padding: 0px 15px;

          &.-noscroll {
               min-width: unset;
          }

          .react-bootstrap-table {
               .table {
                    tbody {
                         td {
                              padding: 1em 12px;
                              word-wrap: break-word;
                         }
                    }
               }
          }
     }
}

/* min-width table */

.acard {
     .index.__full .react-bootstrap-table {
          min-width: 73em;
     }
}

.listcar {
     .index.__full .react-bootstrap-table {
          min-width: 85em;
     }
}

.oderpayment {
     .index.__primary .react-bootstrap-table {
          min-width: 90em;
     }
}

.carpayment {
     .index.__primary .react-bootstrap-table {
          min-width: 77em;
     }
}

.tax {
     .index.__primary .react-bootstrap-table {
          min-width: 116em;
     }
}

.form {
     .index.__primary .react-bootstrap-table {
          min-width: 113em;
     }
}

.matchconfirm {
     .index.__primary .react-bootstrap-table {
          min-width: 130em;
     }
}

.matchlist {
     .index.__primary .react-bootstrap-table {
          min-width: 110em;
     }
}

.device {
     .index.__primary .react-bootstrap-table {
          min-width: 65em;
     }
}

.matchcar {
     .index.__primary .react-bootstrap-table {
          min-width: 75em;
     }
}

.listteam {
     .index.__primary .react-bootstrap-table {
          min-width: 40em;
     }
}

.macthlist_gen {
     .index.index.__summary-no-padding .react-bootstrap-table {
          min-width: 90em;
     }
}

.listlabel {
     .index.__primary .react-bootstrap-table {
          min-width: 100em;
     }
}

.cancellabel {
     .index.__primary .react-bootstrap-table {
          min-width: 130em;
     }
}

.savelabel {
     .index.__primary .react-bootstrap-table {
          min-width: 105em;
     }
}

.registerlabel {
     .index.__primary .react-bootstrap-table {
          min-width: 100em;
     }
}

.listinstall {
     .index.__primary .react-bootstrap-table {
          min-width: 158em;
     }
}

.delivercar {
     .index.__primary .react-bootstrap-table {
          min-width: 125em;
     }
}

.accorder {
     .index.__primary .react-bootstrap-table {
          min-width: 100em;
     }
}

.carorder {
     .index.__primary .react-bootstrap-table {
          min-width: 110em;
     }
}

.orderleasing {
     .index.__primary .react-bootstrap-table {
          min-width: 80em;
     }
}

.discount {
     .index.__primary .react-bootstrap-table {
          min-width: 70em;
     }
}

.item {
     .index.__primary .react-bootstrap-table {
          min-width: 85em;
     }
}

.listleasing {
     .index.__primary .react-bootstrap-table {
          min-width: 65em;
     }
}

.listinsurance {
     .index.__primary .react-bootstrap-table {
          min-width: 30em;
     }
}

.app-item {
     .index.__primary .react-bootstrap-table {
          min-width: 88em;
     }
}

.detail-match-car {
     .react-bootstrap-table {
          min-width: 96em;
     }
}

.ordercar-payment {
     .index.__primary .react-bootstrap-table {
          min-width: 85em;
     }
}

.paymentacc {
     .index.__primary .react-bootstrap-table {
          min-width: 70em;
     }
}

.leasing-payment {
     .index.__primary .react-bootstrap-table {
          min-width: 85em;
     }
}

.listcar {
     .index.__custom .react-bootstrap-table {
          min-width: 80em;
     }
}

.order-item {
     .index.__summary {
          min-width: 135em;
     }
}

.listsupplier {
     .index.__primary .react-bootstrap-table {
          min-width: 60em;
     }
}

.list-car {
     .react-bootstrap-table {
          min-width: 115em;
     }
}

.listreport {
     .index {
          min-width: 10em;
          max-width: 100%;
     }
}

.uploadcar {
     overflow-x: auto;

     .react-bootstrap-table {
          min-width: 150em;
     }
}

.table-content-no-margin {
     #table-content.content {
          margin: 0;
     }
}

.fix-match-confirm {
     margin: 0;
     width: 100%;
     padding: 0 12px;
}

// checkbox in table
.checkcenter {
     .form-check {
          padding: 0;
          text-align: center;
     }

     .form-check-input {
          margin: 0;
     }
}

/* Pagination */
.react-bootstrap-table-pagination {
     // margin: 1em 0;
     margin: 0;

     .react-bootstrap-table-pagination-list {
          &.col-md-6,
          &.col-lg-6 {
               flex: 100%;
               max-width: 100%;
          }

          .pagination {
               justify-content: center;

               .page-item {
                    font-size: 1rem;
                    font-weight: 300;
                    display: flex;
                    margin: 0 0.25em;

                    &.active {
                         .page-link {
                              background-color: $second-color;
                              border-color: $second-color;
                              color: white;
                         }
                    }

                    .page-link {
                         border-radius: 50%;
                         width: 35px;
                         height: 35px;
                         color: $secondary-text;
                         border-color: white;
                    }
               }
          }
     }
}

/* Form */
.form {
     &-control {
          font-weight: 200;
          color: $secondary-text;
          border: 1px solid $light-primary-color;

          &::placeholder {
               color: #bfbfbf;
               font-family: "sukhumvit-light";
          }
     }

     &-group {
          align-self: flex-end;
     }
}

/* Select form */
select {
     &.form-control {
          color: #bfbfbf;
          font-family: "sukhumvit-light";
     }
}

#title,
#check {
     &.form {
          &-group {
               border-bottom: 0.5px solid $primary-color;
               font-weight: 500;
               display: flex;

               #row-check {
                    &.row {
                         width: 80%;
                         margin: auto;
                    }
               }
          }
     }
}

.checkbox-no-margin {
     #check.form-group #row-check.row {
          margin: 0;
     }
}

#check {
     &.form {
          &-group {
               border-bottom: 0;
          }
     }
}

#textheader {
     &.form {
          &-group {
               label {
                    width: 100%;
                    display: flex;
                    margin: 0;

                    strong {
                         font-family: "sukhumvit-medium";
                    }
               }
          }
     }
}

#primary-field {
     &.form {
          &-group {
               display: flex;
          }
     }
}

#summary-field {
     &.form {
          &-group {
               margin-bottom: 0;

               span {
                    font-size: 0.8em;
               }

               #item-number {
                    span {
                         font-family: "sukhumvit-semiBold";
                         color: $second-color;
                    }
               }

               .row {
                    padding: 0.75em;
                    background: $bc-primary;
                    border: 1px solid $bc-border;
                    border-radius: 4px;
                    margin: 0.5em 0;
               }
          }
     }
}

#text-field {
     &__inline {
          &.form-group {
               display: flex;
          }
     }
}

#label-bar-highlight,
#label-bar-highlight__hori {
     &.form {
          &-group {
               display: block;
               background: $bg-footer;
               padding: 1em;
               border-radius: 5px;

               .form-check {
                    padding-left: 2.25em;
               }
          }
     }
}

#label-bar-highlight__hori {
     &.form {
          &-group {
               display: flex;
          }
     }
}

[id$="__inline"],
[id$="__inline__space"] {
     &.form {
          &-group {
               display: flex;
               align-items: center;

               .input-group {
                    align-items: center;
               }

               label {
                    margin-bottom: 0;
               }
          }
     }
}

#col {
     &-summary {
          &-inline,
          &-inline-blue {
               display: flex;

               &.form {
                    &-group {
                         align-items: center;
                    }
               }
          }
     }
}

/* File */
.custom-btn {
     &.__below-btn {
          &.form-group {
               .custom.custom-file {
                    height: fit-content;

                    .custom-file-input {
                         height: 6em;
                    }

                    .custom-file-label {
                         &::after {
                              position: initial;
                              background-color: $success;
                              color: white;
                              padding: 0.75em;
                              border-radius: 0.5em;
                              height: fit-content;
                              text-align: center;
                              margin: 1em -0.75em;
                         }
                    }
               }
          }
     }

     &.__beside-btn {
          &.form-group {
               margin-bottom: 0;

               .custom.custom-file {
                    height: fit-content;

                    .custom-file-input {
                         // height: 3em;
                    }

                    .custom-file-label {
                         &::after {
                              background-color: $success;
                              color: white;
                              // height: fit-content;
                              text-align: center;
                         }
                    }
               }
          }
     }

     &.__beside-btn-nohight {
          &.form-group {
               .custom.custom-file {
                    height: fit-content;

                    .custom-file-label {
                         &::after {
                              background-color: $success;
                              color: white;
                              height: fit-content;
                              text-align: center;
                         }
                    }
               }
          }
     }

     &.__only-btn {
          .form-label {
               display: none;
          }

          .custom.custom-file {
               height: fit-content;

               .custom-file-input {
                    height: 4em;
               }

               .custom-file-label {
                    padding: 0;
                    height: 0;
                    border: 0;

                    &::after {
                         position: initial;
                         background-color: $success;
                         color: white;
                         padding: 0.75em;
                         border-radius: 0.5em;
                         height: fit-content;
                         text-align: center;
                         margin: 0;
                    }
               }
          }
     }
}

/* Accordion */
.accordion {
     width: 100%;
}

/* antd */
.ant-picker {
     width: 100%;
     border: 1px solid $light-primary-color;

     &-input {
          > input {
               font-weight: 200;
               font-size: 1rem;
               color: $secondary-text;
          }
     }
}

/* Input Group */
.input-group {
     .input-text {
          border: 0;
          background: white;
          color: $primary-text;
     }

     &-prepend {
          .input-group-text {
               background: transparent;
               border: 0;
          }
     }
}

.number {
     &.input-group {
          input {
               display: block;
               width: 100%;
               height: calc(1.5em + 0.75rem + 2px);
               padding: 0.375rem 0.75rem;
               font-size: 1rem;
               font-weight: 400;
               line-height: 1.5;
               color: #495057;
               background-color: #fff;
               background-clip: padding-box;
               border: 1px solid #ced4da;
               border-radius: 0.25rem;
               transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          }
     }
}

/* Modal */
.modal {
     &-header {
          justify-content: center;
          border: 0;
          padding: 1em;

          .modal-title {
               font-size: medium;
          }

          .close {
               position: absolute;
               margin: 1rem;
               padding: 0;
               top: 0;
               right: 0;
          }
     }

     &-footer {
          border: 0;
     }
}

/* Tabs */
.nav {
     &-tabs {
          border: 0;
          // margin-bottom: 1em;
     }

     &-item {
          font-family: "sukhumvit-semiBold";

          &.nav-link {
               background: $light-primary-color;
               color: $second-color;

               &.active {
                    border: 0;
               }
          }
     }
}

/* Container */
.tb {
     &.container {
          min-width: 50em;
     }

     .-padding {
          padding: 0px 15px;
     }
}

/* Small desktop size */
@media (max-width: 1300px) {
     .breadcrumb {
          width: 100%;

          .-b__button {
               display: none;
          }

          .-b__button-md {
               display: flex;
               justify-content: flex-end;
               width: auto;

               > .btn {
                    width: 2.5em;

                    > .-icon {
                         margin: 0;
                    }
               }
          }
     }
}

/* Tablet size */
@media (max-width: 1000px) {
     /* Breadcrumb */
     .breadcrumb {
     }

     .label {
          .breadcrumb {
               width: 100%;
          }
     }

     .order {
          .breadcrumb {
               width: 100%;
          }
     }

     /* Accordion */
     .accordion {
          width: 100%;
          margin-bottom: 1em;
     }

     /* Form */
     [id$="__inline"],
     [id$="__inline__space"] {
          &.form {
               &-group {
                    display: block;

                    .form-control {
                         margin: 0.25em 0;
                    }
               }
          }
     }

     /* Table */
     .index {
          &.__summary {
               min-width: 65em;
          }
     }

     /* Tabs */
     .nav {
          &-tabs {
               background: white;
          }
     }

     /* Pagination */
     .react-bootstrap-table-pagination {
          .react-bootstrap-table-pagination-list.col-sm-6 {
               max-width: 0;
               margin: 0 auto;
          }

          .react-bootstrap-table-pagination-list.col-md-6 {
               max-width: 0;
               margin: 0 auto;
          }

          .react-bootstrap-table-pagination-list.col-lg-6 {
               max-width: 0;
               margin: 0 auto;
          }

          .react-bootstrap-table-pagination-list.col-xs-6 {
               max-width: 0;
               margin: 0 auto;
          }
     }
}

/* Mobile size */
@media (max-width: 462px) {
     /* Breadcrumb */
     .breadcrumb {
          .-b__item {
               display: none;
          }

          .-b__button-md {
               width: 100%;
          }
     }

     /* Button */
     .btn {
          margin: 0.5em 0;
     }

     /* Form */
     #textheader {
          &.form {
               &-group {
                    label {
                         display: block;
                    }
               }
          }
     }

     #number-field__inline {
          &.form {
               &-group {
                    display: block;
               }
          }
     }

     #col {
          &-summary {
               &-inline {
                    display: block;
               }
          }
     }
}

.nopadding {
     .input-group-text {
          padding: 6px 12px 6px 0px;
     }
}

.type {
     &.red {
          &.was-validated {
               .form-control:valid {
                    border-color: #dc3545 !important;
                    padding-right: calc(1.5em + 0.75rem);
                    background-image: url(https://www.flaticon.com/svg/vstatic/svg/179/179386.svg?token=exp=1613103924~hmac=616186104a97f3de413a02a0c6873374);
                    background-repeat: no-repeat;
                    background-position: right calc(0.375em + 0.1875rem) center;
                    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
               }
          }
     }

     &.green {
          &.was-validated {
               .form-control:valid {
                    border-color: #28a745 !important;
                    padding-right: calc(1.5em + 0.75rem);
                    // background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Cross_red_circle.svg/1024px-Cross_red_circle.svg.png);
                    background-repeat: no-repeat;
                    background-position: right calc(0.375em + 0.1875rem) center;
                    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
               }
          }
     }
}

.date {
     &.green {
          &.ant-picker {
               width: 100%;
               border: 1px solid #28a745;
          }
     }

     &.red {
          &.ant-picker {
               width: 100%;
               border: 1px solid #dc3545;
          }
     }
}
