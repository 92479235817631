@import '../../styles/color';

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

.skeleton {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;
    width: -webkit-fill-available;
    border-radius: 25px;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg,
                rgba(#fff, 0) 0,
                rgba(#000000, 0.1) 20%,
                rgba(#000000, 0.2) 60%,
                rgba(#fff, 0));
        animation: shimmer 1s infinite;
        content: '';
    }
}