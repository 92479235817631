@import url('https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap');
@import '../../styles/color';

$gmb-loader-size: 30px;

@keyframes showTopText {
    0% {
        transform: translate3d(0, 100%, 0);
    }

    40%,
    60% {
        transform: translate3d(0, 50%, 0);
    }

    100% {
        transform: translate3d(0, 0%, 0);
    }
}

@keyframes showBottomText {
    0% {
        transform: translate3d(0, -100%, 0);
    }

    50% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes gmb-bounce {
    10% {
        transform: translate3d(0, $gmb-loader-size/4, 0);
        animation-timing-function: cubic-bezier(.5, 0, .5, 1);
        height: $gmb-loader-size/4;
    }

    14% {
        height: $gmb-loader-size*3/4;
    }

    15% {
        height: $gmb-loader-size/4;
        transform: translate3d(0, -$gmb-loader-size/2, 0);
        animation-timing-function: cubic-bezier(0.8, 0, .8, 1);
    }

    35% {
        transform: translate3d(0, 0, 0);
    }

}

.loader {
    // height: 100%;
    background: white;

    .animated-title {
        color: #222;
        font-family: 'Poppins';
        height: 20vmin;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 25vmin;
    }

    .animated-title>div {

        overflow: hidden;
        position: absolute;
        width: 100%;
    }

    .animated-title>div div {
        font-size: 2vmin;

        position: absolute;
    }

    .animated-title>div div span {
        font-weight: 800;
        display: block;
    }

    .animated-title>div.text-top {
        border-bottom: 0.25vmin solid #000;
        top: 0;
        height: 50%;
    }

    .animated-title>div.text-top div {
        animation: showTopText 1s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        bottom: -40%;
        padding: 2vmin 0;
        transform: translate(0, 200%);
    }

    .animated-title>div.text-top div span:first-child {
        color: $danger;
        display: flex;

        span:first-child {
            padding-left: 10px;
            color: $primary-color;
        }
    }

    .animated-title>div.text-bottom {
        height: 25%;
        top: 50%;
    }

    .animated-title>div.text-bottom div {
        animation: showBottomText 2s infinite;
        animation-delay: 1.75s;
        animation-fill-mode: forwards;
        top: 0;
        transform: translate(0, -100%);

        // .gmb-loader {
        //     font-size: 0;
        //     display: inline-block;
        //     position: relative;
        //     height: $gmb-loader-size;
        //     width: $gmb-loader-size;

        //     > div {
        //         background: $primary-color;
        //         width: $gmb-loader-size/4;
        //         height: $gmb-loader-size/4;
        //         border-radius: $gmb-loader-size/4;
        //         position: absolute;
        //         animation: gmb-bounce 1.75s infinite;
        //         top: 50%;
        //         margin-top: -$gmb-loader-size/8;

        //         &:nth-child(2) {
        //             animation-delay: 0.25s;
        //             left: 50%;
        //             margin-left: -$gmb-loader-size/8;
        //         }

        //         &:nth-child(3) {
        //             animation-delay: 0.5s;
        //             right: 0;
        //         }
        //     }
        // }

        // .table {
        //     display: table;
        //     width: 100%;
        //     height: 100%;
        //     text-align: center;

        //     .cell {
        //         display: table-cell;
        //         vertical-align: end;
        //     }
        // }

    }
}