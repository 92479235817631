@import url("https://fonts.googleapis.com/css?family=Prompt:200,300,400,500,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,800&display=swap");
@import "custom-boostrap";
@import "color";
@import "font";

// @font-face {
//   font-family: 'sukhumvit-semiBold';
//   src: url('../assets/fonts/SukhumvitSet-SemiBold.ttf') format('truetype');
//   unicode-range: U+0E00- U+0E7F;
// }
// @font-face {
//   font-family: 'sukhumvit-light';
//   src: url('../assets/fonts/SukhumvitSet-Light.ttf') format('truetype');
//   unicode-range: U+0E00- U+0E7F;
// }

#root {
     height: 100%;
}

html,
body {
     height: 100%;
     margin: 0;
     font-family: "sukhumvit-light", "sukhumvit-semiBold", "Poppins";
     font-weight: 300;
     color: $primary-text;
     background: $bg-body;

     /* width */
     ::-webkit-scrollbar {
          width: 5px;
          height: 8px;
     }

     /* Track */
     ::-webkit-scrollbar-track {
          background: #fff;
          border-radius: 50%;
     }

     /* Handle */
     ::-webkit-scrollbar-thumb {
          background: #888;
     }

     /* Handle on hover */
     ::-webkit-scrollbar-thumb:hover {
          background: #555;
     }
}

/* Width */

/* font set */
h1,
h2,
h3,
h4,
h5 {
     font-family: "sukhumvit-semiBold";
}

strong {
     font-family: "sukhumvit-medium";
}

label {
     margin: 0 0.5rem 0.5rem 0;
}

label {
     &.form-check-label {
          font-weight: 300;
     }
}
.swal-text {
     text-align: center;
}
/* Font */
span {
     &.underline-date {
          color: black;
          display: inline-block;
          border-bottom: 0.5px solid grey;
          padding-left: 20px;
          padding-right: 20px;
     }

     &.underline-0 {
          color: black;
          display: inline-block;
          border-bottom: 0.5px solid grey;
          padding-left: 12%;
          padding-right: 25%;
     }

     &.underline-1 {
          color: black;
          display: inline-block;
          border-bottom: 0.5px solid grey;
          padding-left: 5%;
          padding-right: 23%;
     }

     &.underline-2 {
          color: black;
          display: inline-block;
          border-bottom: 0.5px solid grey;
          padding-left: 5%;
          padding-right: 13%;
     }

     &.underline-3 {
          color: black;
          display: inline-block;
          border-bottom: 0.5px solid grey;
          padding-left: 5%;
          padding-right: 25%;
     }

     &.underline-4 {
          color: black;
          display: inline-block;
          border-bottom: 0.5px solid grey;
          padding-left: 5%;
          padding-right: 35%;
     }

     &.underline-5 {
          color: black;
          display: inline-block;
          border-bottom: 0.5px solid grey;
          padding-left: 5%;
          padding-right: 23%;
     }

     &.underline-6 {
          color: black;
          display: inline-block;
          border-bottom: 0.5px solid grey;
          padding-left: 5%;
          padding-right: 38%;
     }

     &.underline-7 {
          color: black;
          display: inline-block;
          border-bottom: 0.5px solid grey;
          padding-left: 5%;
          padding-right: 31%;
     }

     &.upload {
          color: grey;
          text-decoration: underline;
          &.open {
               color: $second-color;
               text-decoration: underline;
               cursor: pointer;
          }
          &.noline {
               text-decoration: unset;
          }
     }

     &.detail {
          color: $second-color;

          &.underline {
               text-decoration: underline;
               cursor: pointer;
          }
     }

     &.warning {
          margin-left: 1em;
          color: red;

          &.__info {
               margin: 0;
               font-size: 0.75em;
               color: $danger;
          }
     }

     &.success {
          color: $success;
     }

     &.doc {
          color: $font-table;

          &.active {
               color: $black;
          }
     }

     &.info {
          color: $info-text;
     }

     &.sign-placeholder {
          font-family: "sukhumvit-medium";
          color: $info-text;
     }

     &.summary-value {
          padding: 0.375rem 0.75rem;
          font-size: 1.25em;
          font-family: "sukhumvit-medium";
          color: $third-color;
     }
     &.gray {
          color: #8a8f9c;
     }
}

/* Image set */
img {
     object-fit: contain;
     object-position: center;

     &.preview {
          width: 40%;
     }

     &.logo {
          width: 100%;
          height: auto;
     }
}

/* Icon */
.icon {
     width: 1.25em;
     height: auto;
     margin-right: 0.5em;

     &.__btn {
          cursor: pointer;
     }

     &.__danger {
          color: $danger;
     }
}

.fa,
.fas {
     &-edit,
     &-print {
          // cursor: pointer;
     }
}

/* Panel */
.panel {
     height: 100%;

     &.hide-scroll {
          overflow-y: hidden;
     }

     > .row {
          margin: 0;
     }

     .logo {
          margin: 1em 0;

          &.__header {
               &.row {
                    width: 30%;
                    margin: auto;
               }
          }

          &.__footer {
               &.row {
                    width: 50%;
                    margin: auto;
               }
          }
     }
}

#panel__login {
     background: url("../assets/images/toyota_bg.png");
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     justify-content: center;
     display: flex;
     align-items: center;

     > div {
          width: 100%;
     }
}

/* Margin Padding */
.noPadding,
.col {
     padding-left: 0;
     padding-right: 0;
}

// .__padding {

//      .noPadding,
//      .col {
//           padding: 0 15px;
//      }

//      label {
//           margin: 0;
//      }

//      .-field {
//           align-self: center;

//           .noPadding,
//           .col {
//                padding: 0.25em 15px 0 15px;
//                margin: 0;
//           }

//           label {
//                margin: 0;
//                padding-right: 15px;
//           }

//           .input-group-prepend .input-group-text {
//                padding: 0 1em;
//           }
//      }
// }

.noMargin {
     margin-left: 0;
     margin-right: 0;
}

/* Form Table */
.cell-form-table {
     border-left: 0.75px solid $second-color;
     border-top: 0.75px solid $second-color;
     padding: 0.5em;
     word-break: break-word;
}

.num {
     text-align: end;
}

.header {
     text-align: center;
}

#table__last-cell {
     border-right: 0.75px solid $second-color;
}

#table__last-row {
     border-right: 0.75px solid $second-color;
     border-bottom: 0.75px solid $second-color;
     border-top: 0.75px solid $second-color;
     padding: 0.5em;
     word-break: break-word;
}

/* Content */
.content {
     display: flex;
     width: 90%;
     margin: auto;

     &.__main-panel {
          padding: 7em 0;
          justify-content: space-between;
          width: 95%;
          max-height: 100vh;
     }

     &.__input-panel,
     &.__ac-panel {
          display: block;
          padding-top: 6em;
          width: 95%;
     }

     &.__ac-panel {
          padding-top: 0;
          width: 100%;
     }

     .input-auto {
          background: aliceblue;
          padding: 17px 10px;
          margin-bottom: 1em;
          border-radius: 6px;
          align-self: center;

          .form-group {
               margin-bottom: 0;
          }
     }

     .search-bar {
          width: 20%;

          //Add
          button {
               margin: 10px 0;
               &.-header {
                    margin: 0;
               }
          }
          label {
               &.custom-file-label {
                    margin: unset;
               }
          }
          .custom {
               &.custom-file {
                    margin-bottom: 20px;
               }
          }
     }

     #bar-ac {
          &.search-bar {
               padding: 0 0.25em 0.25em 0;

               > .-body {
                    border-bottom-left-radius: 0.25rem;
                    border-bottom-right-radius: 0.25rem;
               }
          }
     }

     .search,
     .input-field {
          width: 100%;

          > .-header {
               padding: 0.75rem;
               background: $second-color;
               color: white;
               border-top-left-radius: 0.25rem;
               border-top-right-radius: 0.25rem;
               text-align: center;
               border: 0;
               font-weight: 200;
               width: 100%;
          }

          > .-body {
               background: white;

               .content {
                    width: 85%;
                    padding: 1em 0;
                    display: block;

                    .password-trigger {
                         position: absolute;
                         top: 55%;
                         right: 1.5rem;
                         color: #666;
                         z-index: 1;
                    }
               }
          }
     }

     .input-field {
          width: 100%;
          margin: 1em 0;

          > .-body {
               border-bottom-left-radius: 0.25rem;
               border-bottom-right-radius: 0.25rem;
               // margin: 20px 0px;'
               margin: 0;

               .content {
                    width: 90%;

                    &.__auto {
                         width: 100%;
                         padding: 0;
                    }

                    .-padding {
                         padding: 0px 15px;
                    }

                    .-padding-lg {
                         padding: 0px 3rem;
                    }
               }

               #table-content {
                    &.content {
                         overflow: auto;
                    }
               }
          }

          &.__tab-body {
               margin: 0;
          }
     }

     #field-summary {
          &.input-field {
               margin: 0 0 0.25em 0;

               > .-body {
                    border-radius: 0.25rem;
               }
          }
     }

     /* Table */
     .index {
          // width: 100%;

          &.__primary {
               width: 78%;
               overflow: auto;
          }

          &.__add {
               display: flex;
               margin: 1em 0;

               .react-bootstrap-table {
                    max-height: 50vh;
                    overflow-y: scroll;
                    display: block;
               }

               > div {
                    &.-btn {
                         width: 15%;
                    }

                    &.-table {
                         width: 80%;
                         margin: auto;
                         overflow: auto;
                    }
               }
          }

          &.__field {
               overflow: auto;
               padding: 1em 0;
               display: block;
               width: 100%;
          }

          // &.__custom,
          // &.__summary {
          //   width: 100%;
          // }
     }

     .search-bar {
          width: 20%;
     }

     #bar-ac {
          &.search-bar {
               padding: 0 0.25em 0.25em 0;

               > .-body {
                    border-bottom-left-radius: 0.25rem;
                    border-bottom-right-radius: 0.25rem;
               }
          }
     }

     .search,
     .input-field {
          width: 100%;

          > .-header {
               padding: 0.75rem;
               background: $second-color;
               color: white;
               border-top-left-radius: 0.25rem;
               border-top-right-radius: 0.25rem;
               text-align: center;
               border: 0;
               font-weight: 200;
               width: 100%;
          }

          > .-body {
               background: white;

               .content {
                    width: 85%;
                    padding: 1em 0;
                    display: block;
               }
          }
     }

     .input-field {
          width: 100%;
          margin: 1em 0;

          > .-body {
               border-bottom-left-radius: 0.25rem;
               border-bottom-right-radius: 0.25rem;
               // margin: 20px 0px;'
               margin: 0;

               .content {
                    width: 90%;

                    &.__auto {
                         width: 100%;
                         padding: 0;
                    }

                    .-padding {
                         padding: 0px 15px;
                    }

                    .-padding-lg {
                         padding: 0px 3rem;
                    }
               }

               #table-content {
                    &.content {
                         overflow: auto;
                    }
               }
          }

          &.__tab-body {
               margin: 0;
          }
     }

     #field-summary {
          &.input-field {
               margin: 0 0 0.25em 0;

               > .-body {
                    border-radius: 0.25rem;
               }
          }
     }

     /* Table */
     .index {
          // width: 100%;

          &.__primary {
               width: 78%;
               overflow: auto;
          }

          &.__add {
               display: flex;

               margin: 1em 0;

               .react-bootstrap-table {
                    max-height: 50vh;
               }
          }

          // &.__custom,
          // &.__summary {
          //   width: 100%;
          // }
     }

     .left,
     .right {
          // margin: 0 5px;
     }

     .right {
          background: cadetblue;
     }
}

.noMargin {
     .table {
          margin: 0;
     }
}

.tableFixHead {
     overflow-y: auto;
     height: 60vh;

     thead {
          th {
               position: sticky;
               top: 0;
               background: #1d419b;
               z-index: 1;
          }
     }
}

.tableFixHead-modal {
     overflow-y: auto;
     height: 500px;

     thead {
          th {
               position: sticky;
               top: 0;
               background: #1d419b;
               z-index: 1;
          }
     }
}

/* Form Table */
.form-table {
     thead {
          .tr {
               vertical-align: middle;
               color: #fff;

               &.__center {
                    text-align: center;
               }

               th {
                    &.__orange {
                         background-color: #e3811f;
                    }
                    &.__gold {
                         background-color: #dca201;
                    }
                    &.__green {
                         background-color: #88b759;
                    }
                    &.__sky {
                         background-color: #6ec3fa;
                    }
                    &.__violet {
                         background-color: #80589b;
                    }
                    &.__blue {
                         vertical-align: middle;
                         background-color: #1d419b;
                         &.-width {
                              min-width: 10em;
                         }
                    }
               }
          }
          .tr-2 {
               th {
                    text-align: center;
                    &.__orange-sec {
                         background-color: #ffd6ac;
                         min-width: 10em;
                    }
                    &.__gold-sec {
                         background-color: #fbebbe;
                         min-width: 10em;
                    }
                    &.__green-sec {
                         background-color: #d3ebbc;
                         min-width: 16em;
                    }
                    &.__sky-sec {
                         background-color: #d6efff;
                         min-width: 18em;
                    }
                    &.__violet-sec {
                         background-color: #ede1f5;
                         min-width: 18em;
                    }
               }
          }
     }
     .field-table {
          .form-group {
               margin: 0;
          }
     }
}

/* Button group */
.button-group {
     display: flex;
     width: 100%;

     &.__submit {
          width: 50%;
          margin: 1em auto;
          justify-content: center;
          padding: 1em 0;
     }

     &.__onPanel {
          background: white;
          border-radius: 0.25rem;
          margin: 1em 0;
          padding: 1em;
     }

     /*----------Modal----------*/
     &.__submit_modal {
          width: 100%;
          margin: 1em auto;
          justify-content: center;
     }

     &.__modal-btn-select {
          margin-top: 0;
          padding-top: 0;
     }
}

/* Banner */
.banner {
     &-cell {
          padding: 0 0.25em 0.25em 0;

          &-content {
               padding: 1.5em;
               border-radius: 4px;
               margin: auto;
               height: 100%;
               > .-text,
               h4 {
                    color: white;
               }

               > .-img {
                    margin: auto;
                    justify-content: center;
                    display: flex;

                    img {
                         width: 100%;
                         max-width: 9em;
                    }
               }
          }
     }

     #cell-1 {
          .banner-cell-content {
               background: linear-gradient(135deg, rgba(29, 65, 155, 1) 0%, rgba(110, 195, 250, 1) 100%);

               img {
                    max-width: 5em;
               }
          }
     }

     #cell-2 {
          .banner-cell-content {
               background: linear-gradient(135deg, rgba(255, 83, 83, 1) 0%, rgba(230, 202, 23, 1) 100%);
          }
     }

     #cell-3 {
          .banner-cell-content {
               background: linear-gradient(135deg, rgba(25, 214, 181, 1) 0%, rgba(204, 230, 23, 1) 100%);
          }
     }

     #cell-4 {
          .banner-cell-content {
               background: linear-gradient(135deg, rgba(110, 195, 250, 1) 0%, rgba(117, 240, 245, 1) 100%);

               img {
                    max-width: 5em;
               }
          }
     }
}

/* Swal */
.swal {
     &-title {
          font-family: "sukhumvit-semibold";
          font-size: large;
          color: $primary-text;
     }

     &-text {
          font-family: "sukhumvit-text";
          font-size: medium;
          color: black;
     }

     &-footer {
          text-align: center;
     }

     &-button {
          font-family: "sukhumvit-light";
          font-weight: normal;

          &--submit {
               background: $primary-color;
          }

          &--next {
               background: white;
               border: 1px solid $primary-color;
               color: $primary-color;
          }
     }
}

@media (max-width: 1500px) {
     .label .breadcrumb {
          width: 100%;
          .color {
               &.__success {
                    text-align: center;
                    padding-left: 52px;
               }
          }
     }
}

/* Small desktop size */
@media (max-width: 1300px) {
     .label .breadcrumb {
          width: 100%;
          .color {
               &.__success {
                    text-align: unset;
                    padding-left: unset;
               }
          }
     }
     .order .breadcrumb {
          width: 100%;
     }
}
@media (max-width: 1280px) {
     //Bsreadcrumb
     .label .breadcrumb {
          // width: 100%;
          .color {
               &.__success {
                    text-align: unset;
                    padding-left: unset;
                    width: calc(100% - 512px);
               }
          }
     }
}
/* Tablet size */
@media (max-width: 1024px) {
     /* Panel */
     .panel {
          &.hide-scroll {
               overflow-y: unset;
          }
          .logo {
               &.__header {
                    &.row {
                         width: 50%;
                         margin: auto;
                    }
               }
          }
     }
     //Bsreadcrumb
     .label .breadcrumb {
          // width: 100%;
          .color {
               &.__success {
                    text-align: unset;
                    padding-left: unset;
                    width: calc(100% - 462px);
               }
          }
     }

     /* Content */
     .content {
          display: block;

          .index {
               &.__primary {
                    width: 100%;
               }
          }

          .search-bar {
               width: 100%;
          }

          .input-field {
               > .-body {
                    .content {
                         width: 95%;
                    }
               }
          }
     }

     /* Button group */
     .button-group {
          display: flex;

          &.__submit {
               width: 90%;
               margin: 2em auto;
          }
     }

     .banner {
          &-cell {
               &-content {
                    min-width: 100%;
               }
          }
     }
}

@media (max-width: 768px) {
     //Bsreadcrumb
     .label {
          .breadcrumb {
               .__row-right {
                    .col {
                         margin: unset;
                    }
               }
          }
     }
}

//Fix Table With Mimimal Height Screen
// @media (max-height: 850px) {
//      .tableFixHead {
//           height: 650px;
//      }
// }

// @media (max-height: 800px) {
//      .tableFixHead {
//           height: 600px;
//      }
// }
// @media (max-height: 750px) {
//      .tableFixHead {
//           height: 550px;
//      }
// }
// @media (max-height: 700px) {
//      .tableFixHead {
//           height: 500px;
//      }
// }
// @media (max-height: 650px) {
//      .tableFixHead {
//           height: 450px;
//      }
// }
// @media (max-height: 600px) {
//      .tableFixHead {
//           height: 400px;
//      }
// }

/* */
.form-field {
     margin: "0px 15px 0px 15px";
     padding: "0px 0px";
}

.text-underline .detail {
     text-decoration: underline;
     cursor: pointer;
}

.sigCanvas {
     width: 100%;
     height: 100%;
}

.acard {
     font-size: 15px;
}

.sm {
     .ant-picker-input {
          height: 16px;
     }
}

.search-edit {
     .search-bar {
          width: 100%;
          margin: 0 auto;
     }
}

.upload-customs {
     width: 54%;
     margin: 0 auto;
}

.form-nopadding {
     .form-group {
          padding: 0;
     }
}

.form-group {
     &.margin {
          margin-top: 1rem;
     }
}

.react-bootstrap-table-pagination {
     position: fixed;
     bottom: 1px;
     width: 55%;
}

.row-date {
     display: flex;
     flex-wrap: wrap;
     margin-right: 15px;
     margin-left: 15px;
     padding-top: 15px;
}

.maxwidt_tbl {
     min-width: 500px;
}

.btn-button-add-order {
     margin-left: 5px;
     border-radius: 40px;
     width: 38px;
     border: #19722e;
     background: #88b759;
     color: white;
     font-weight: bold;
     font-weight: bold;
     height: 38px;
     padding-top: 6px;
}
