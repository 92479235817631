@import "../../styles/color";
// Your variable overrides
$sidebar-bg-color: $white;
$breakpoint-lg: 1300px !default;
$sidebar-width: 320px !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.side-bar {
     // p {
     //      color: $secondary-text;
     // }

     #icon__sidebar {
          display: none;
     }

     .pro-sidebar {
          position: fixed;
          color: $secondary-text;
          .pro-menu a {
               color: $secondary-text;
               &:hover {
                    color: $white;
               }
          }
          .pro-menu-item.active a {
               color: $white;
          }
          .content {
               width: 90%;
               margin: auto;
               display: block;

               > .-img {
                    display: flex;
                    max-height: 6em;

                    img {
                         width: 50%;
                    }
               }

               > .-text {
                    margin: 1em auto;

                    &.icon {
                         cursor: pointer;
                         width: 100%;
                    }
               }
          }

          &-inner {
               .pro-sidebar-layout {
                    .pro-sidebar-header {
                         border-bottom: 0;
                    }
               }
          }

          &-header {
               background-size: cover;
               background-repeat: no-repeat;
               background-position: center;
               background-image: url("../../assets/images/toyota_bg.png"), linear-gradient(to bottom, #fff, #ffffff);
          }

          &-content {
               .pro-menu {
                    &-main {
                         margin: 0 1em;
                    }

                    &-item {
                         margin: 0 1em;
                         font-weight: 200;
                         color: $secondary-text;

                         &.active {
                              margin: 0;
                              background: $third-color;
                              font-weight: 300;
                         }

                         &.pro-sub-menu {
                              .pro-inner-list-item {
                                   background-color: $bg-table;
                                   font-weight: 300;
                                   padding: 0;

                                   > div {
                                        .pro-menu-item {
                                             margin: 0;
                                        }

                                        > ul {
                                             padding: 0;
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }
}

.pro-inner-item {
     &:hover {
          background-color: $third-color;
     }
}

/*Small desktop size*/
@media (max-width: 1300px) {
     .side-bar {
          #icon__sidebar {
               display: initial;
               position: fixed;
               z-index: 100;
               height: 5em;
               margin: 0;
          }
     }
}

.change-password :hover {
     cursor: pointer;
     text-decoration: underline;
}
.ps-noti :hover {
     cursor: pointer;
     text-decoration: underline;
}
