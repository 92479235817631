.navigation {
     height: 100%;
     &.-addsidebar {
          margin-left: 320px;
     }
}

/*Small desktop size*/
@media (max-width: 1300px) {
     .navigation {
          &.-addsidebar {
               margin-left: 0;
               #icon__sidebar {
                    display: initial;
               }
          }
     }
}

select.form-control {
     color: #000000ba !important;
     font-family: "sukhumvit-light";
}
::placeholder {
     color: #000000ba !important;
}
