$primary-text: #2e384d;
$secondary-text: #000;
$info-text: #b3b3b3;

$disabled-color: #e9ecef;

$light-primary-color: #e0e7ff;
$primary-color: #214097;
$second-color: #1d419b;
$third-color: #102a6e;

$danger: #d86060;
$success: #88b759;

$white: #fff;
$black: #262626;

$bg-body: #f5f5f5;
$bg-body-2: #fff9d5;
$bg-table: #fbfbfb;
$bg-footer: #eef3ff;
$font-table: #8A8F9C;

$bc-primary: #F9FAFE;
$bc-border: #E0E7FF;

$card-hover: #F6F8FF;
$card-shadow: #C8C9CC;