@import "../../../styles/color.scss";

@keyframes showTopText {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -20%, 0);
  }
}

@keyframes showTopImg {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -5%, 0);
  }
}

.dashboard {
  .carousel {
    width: 80%;
    margin: auto;
    display: flex;
    height: 300px;
    overflow: scroll;

    > div {
        display: flex;
        margin: auto;
        justify-content: center;
    }

    .flickity-viewport {
      width: 100%;
      height: 260px !important;
    }

    .flickity-page-dots {
      bottom: -10px;
    }

    .carousel-cell {
      background-color: white;
      width: 250px;
      height: 250px;
      padding: 1.5em;
      justify-content: center;
      text-align: center;
      border-radius: 25px;
      border: none;
      transition: 0.3s ease-out;
      margin: 0 1em;

      img {
        width: 100%;
        height: 60%;
      }

      h4 {
        margin-top: 1.5em;
        z-index: 12;
      }

      .animate-finance {
        height: 60%;

        img {
          width: 100%;
        }

        #layer-1,
        #layer-2,
        #layer-3,
        #layer-4 {
          position: relative;
          width: auto;
          height: auto;
        }

        #layer-1 {
          z-index: 11;
          left: 10%;
          top: 23%;
          height: 6em;
        }

        #layer-2 {
          z-index: 10;
          
          left: -9%;
          top: 24%;
        }

        #layer-3 {
          top: -13%;
          left: 5%;
          z-index: 9;
         
        }

        #layer-4 {
          
          top: -136%;
        }
      }

      &:hover {
        background: $card-hover;
        cursor: pointer;
        box-shadow: 0px 3px 10px $card-shadow;
        padding: 1em;

        h4 {
          animation: showTopText 0.3s;
          animation-fill-mode: forwards;
        }
      }
    }

    #animate {
      &.carousel-cell {
        &:hover {
          padding: 1.5em;

          #layer-2 {
            animation: showTopImg 0.3s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
}

/* Small desktop size */
@media (max-width: 1300px) {
  .dashboard {
    .carousel {
      width: 85%;
    }
  }
}

/* Tablet size */
@media (max-width: 1000px) {
    .dashboard {
        .carousel {
          width: 85%;
        }
      }
}