.rbt-input-multi.form-control {
     height: auto !important;
     min-height: 38px !important;
}
.rbt-input-multi {
     cursor: text !important;
     overflow: hidden !important;
     position: relative !important;
}
.rbt-input-multi .rbt-input-wrapper {
     align-items: flex-start !important;
     display: flex !important;
     flex-wrap: wrap !important;
     margin-bottom: -4px !important;
     margin-top: -1px !important;
     overflow: hidden !important;
}
.rbt-token-removeable {
     cursor: pointer !important;
     padding-right: 21px !important;
}
.rbt-token {
     background-color: #e7f4ff !important;
     border: 0 !important;
     border-radius: 0.25rem !important;
     color: #007bff !important;
     display: inline-block !important;
     line-height: 1em !important;
     margin: 1px 3px 2px 0 !important;
     padding: 4px 15px !important;
     position: relative !important;
}
.rbt-token .rbt-token-remove-button {
     bottom: 0 !important;
     color: inherit !important;
     font-size: inherit !important;
     font-weight: normal !important;
     opacity: 1 !important;
     outline: none !important;
     padding: 0px 0px !important;
     position: absolute !important;
     right: 0 !important;
     text-shadow: none !important;
     top: -2px !important;
}
.rbt-token .rbt-token-remove-button span {
     color: red !important;
}
