@import '../../styles/color';

.default-no-btn {
    display: block;
    width: auto;
    height: 200px;
    border: 1px solid $info-text;
    margin-bottom: 1em;
    border-radius: 8px;

    .-placeholder {
        position: relative;
        bottom: 60%;
        text-align: center;
        opacity: 0.3;
    }
}